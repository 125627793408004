<template>
  <div class="service_and_support_nav">
    <div class="title NotoSansTCBold">
      {{ $t("localization.components.serviceAndSupport.title") }}
    </div>
    <div class="navView">
      <el-row :gutter="24">
        <el-col :sm="6" :xs="12"
          ><router-link
            to="/calibration_and_maintenance"
            class="navItem NotoSansTCMedium"
            :class="activeIndex == 0 ? 'active' : ''"
          >
            <div class="nav_slide_wrap">
              <div class="nav_slide_view">
                <div class="iconView">
                  <img src="@/assets/images/service/icon1.png" alt="" />
                  <span>
                    {{
                      $t("localization.components.serviceAndSupport.nav")[0]
                    }}</span
                  >
                </div>
                <div class="iconLogoView">
                  <img src="@/assets/images/service/icon_logo.png" alt="" />
                </div>
              </div>
            </div> </router-link
        ></el-col>
        <el-col :sm="6" :xs="12"
          ><router-link
            to="/download_center"
            class="navItem NotoSansTCMedium"
            :class="activeIndex == 1 ? 'active' : ''"
          >
            <div class="nav_slide_wrap">
              <div class="nav_slide_view">
                <div class="iconView">
                  <img src="@/assets/images/service/icon2.png" alt="" />
                  <span>
                    {{
                      $t("localization.components.serviceAndSupport.nav")[1]
                    }}</span
                  >
                </div>
                <div class="iconLogoView">
                  <img src="@/assets/images/service/icon_logo.png" alt="" />
                </div>
              </div>
            </div> </router-link
        ></el-col>
        <el-col :sm="6" :xs="12"
          ><router-link
            to="/online_teaching"
            class="navItem NotoSansTCMedium"
            :class="activeIndex == 2 ? 'active' : ''"
          >
            <div class="nav_slide_wrap">
              <div class="nav_slide_view">
                <div class="iconView">
                  <img src="@/assets/images/service/icon3.png" alt="" />
                  <span>{{
                    $t("localization.components.serviceAndSupport.nav")[2]
                  }}</span>
                </div>
                <div class="iconLogoView">
                  <img src="@/assets/images/service/icon_logo.png" alt="" />
                </div>
              </div>
            </div> </router-link
        ></el-col>
        <el-col :sm="6" :xs="12"
          ><router-link
            to="/knowledge_base"
            class="navItem NotoSansTCMedium"
            :class="activeIndex == 3 ? 'active' : ''"
          >
            <div class="nav_slide_wrap">
              <div class="nav_slide_view">
                <div class="iconView">
                  <img src="@/assets/images/service/icon4.png" alt="" />
                  <span>{{
                    $t("localization.components.serviceAndSupport.nav")[3]
                  }}</span>
                </div>
                <div class="iconLogoView">
                  <img src="@/assets/images/service/icon_logo.png" alt="" />
                </div>
              </div>
            </div> </router-link
        ></el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    //高亮
    activeIndex: {
      type: Number,
      default() {
        return null;
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.service_and_support_nav {
  padding: 100px 0;
  @include respond-to(lg) {
    padding: 50px 0;
  }
  @include respond-to(sm) {
    padding: 20px 0 0;
  }
  .title {
    text-align: center;
    font-size: 40px;
    color: #1a1a1a;
    margin-bottom: 50px;
    @include respond-to(lg) {
      font-size: 30px;
      margin-bottom: 30px;
    }
    @include respond-to(sm) {
      margin-bottom: 20px;
      font-size: 24px;
    }
  }
  .navView {
    .el-col {
      @include respond-to(sm) {
        margin-bottom: 20px;
      }
    }
    .navItem {
      display: block;
      width: 100%;
      height: 200px;
      @include respond-to(sm) {
        height: 150px;
      }
      .nav_slide_wrap {
        width: 100%;
        height: 200px;
        overflow: hidden;
        background-color: #ffffff;
        box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.27);
        @include respond-to(sm) {
          height: 150px;
        }
        .nav_slide_view {
          width: calc(100% + 90px);
          display: flex;
          height: 100%;
          transition: all 0.3s cubic-bezier(0.01, 0.2, 0.5, 0.18);
          .iconView {
            width: calc(100% - 90px);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: #1a1a1a;
            font-size: 18px;
            img {
              display: block;
              margin-bottom: 12px;
              @include respond-to(sm) {
                width: 100px;
              }
            }
            img,
            span {
              transition: all 0.3s cubic-bezier(0.01, 0.2, 0.5, 0.18);
            }
          }
          .iconLogoView {
            box-sizing: border-box;
            width: 90px;
            background-color: #0075c1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            padding-bottom: 26px;
            @include respond-to(sm) {
              width: 50px;
              img {
                width: 30px;
              }
            }
          }
        }
      }
      &.active {
        pointer-events: none;
      }
      &.active,
      &:hover {
        .nav_slide_wrap {
          .nav_slide_view {
            margin-left: -90px;
            @include respond-to(sm) {
              margin-left: -50px;
            }
            .iconView {
              img,
              span {
                margin-right: -90px;
                @include respond-to(sm) {
                  margin-right: -50px;
                }
              }
            }
          }
        }
      }
      @include respond-to(sm) {
        &:hover {
          .nav_slide_wrap {
            .nav_slide_view {
              margin-left: 0;
              @include respond-to(sm) {
                margin-left: 0;
              }
              .iconView {
                img,
                span {
                  margin-right: 0;
                  @include respond-to(sm) {
                    margin-right: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
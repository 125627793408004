<!--
 * @Author: 苏航
 * @Date: 2021-05-25 08:35:14
 * @LastEditors: 苏航
 * @LastEditTime: 2021-07-26 13:00:32
 * @version: V.x
 * @Descripttion: 说明...
-->
<template>
  <div class="contact_us">
    <div class="title">{{ $t("localization.components.contactUs.title") }}</div>
    <div class="introduce">
      <!-- {{ $t("localization.components.contactUs.introduce") }} -->
      博銳位於台北的子公司提供完整的產品售前與售後諮詢服務,
      另外也提供產品的校正與維修服務. 歡迎您來信或電話查詢。
    </div>
    <div class="linkView">
      <div class="linkItem">
        <img class="icon" src="@/assets/images/service/icon6.jpg" alt="" />
        <h4>{{ $t("localization.components.contactUs.link")[0].title }}</h4>
        <!-- <p>{{ $t("localization.components.contactUs.link")[0].txt }}</p> -->
        <p>針對產品或應用的諮詢</p>
        <router-link to="/product_consulting" class="link">{{
          $t("localization.components.contactUs.link")[0].btn
        }}</router-link>
      </div>
      <div class="linkItem">
        <img class="icon" src="@/assets/images/service/icon5.jpg" alt="" />
        <h4>{{ $t("localization.components.contactUs.link")[1].title }}</h4>
        <!-- <p>{{ $t("localization.components.contactUs.link")[1].txt }}</p> -->
        <p>針對產品售後或應用的諮詢</p>
        <router-link to="/technical_support" class="link">{{
          $t("localization.components.contactUs.link")[1].btn
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.contact_us {
  padding-bottom: 100px;
  @include respond-to(lg) {
    padding-bottom: 50px;
    width: 100%;
  }
  .title {
    font-size: 30px;
    color: #1a1a1a;
    margin-bottom: 30px;
    @include respond-to(lg) {
      font-size: 24px;
      margin-bottom: 20px;
    }
    @include respond-to(sm) {
      margin-bottom: 10px;
      font-size: 18px;
    }
  }
  .introduce {
    font-size: 16px;
    color: #1a1a1a;
    line-height: 1.8;
    width: 800px;
    margin-bottom: 60px;
    @include respond-to(lg) {
      font-size: 14px;
      width: 100%;
    }
    @include respond-to(sm) {
      margin-bottom: 30px;
      font-size: 12px;
    }
  }
  .linkView {
    display: flex;
    .linkItem {
      width: 200px;
      text-align: center;
      margin-right: 30px;
      h4 {
        font-size: 18px;
        color: #1a1a1a;
        margin: 10px 0;
      }
      p {
        font-size: 14px;
        color: #666666;
        line-height: 1.8;
        @include respond-to(sm) {
          display: none;
        }
      }
      .link {
        margin-top: 20px;
        display: inline-flex;
        height: 42px;
        background-color: #0075c1;
        border-radius: 21px;
        width: 100px;
        border: 1px solid #0075c1;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #fff;
        &:hover {
          color: #0075c1;
          background-color: transparent;
        }
      }
    }
  }
}
</style>